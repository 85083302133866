<template>
    <b-overlay :show="loading" rounded="sm">
        <b-card no-body class="d-flex flex-column p-3" style="row-gap: 16px">
            <div class="d-flex justify-content-between">
                <h4>Templates</h4>
                <b-button
                    v-if="$validatePermission('/Contracts-Add')"
                    variant="primary"
                    @click="$bvModal.show('addTemplateModal')"
                >
                    Add Template
                </b-button>
            </div>
            <b-row>
                <b-col cols="12">
                    <b-table
                        small
                        show-empty
                        :filter="filter"
                        striped
                        head-variant="light"
                        :items="templates"
                        hover
                        :fields="[
                            { key: 'id', label: 'ID' },
                            { key: 'name', label: 'Name' },
                            { key: 'fileName', label: 'FileName' },
                            { key: 'isActive', label: 'Status' },
                            { key: 'action', label: 'Action' },
                        ]"
                    >
                        <template v-slot:cell(isActive)="data">
                            <b-badge :variant="data.value ? 'success' : 'danger'">
                                {{ data.value ? 'Active' : 'Inactive' }}
                            </b-badge>
                        </template>
                        <template v-slot:cell(action)="data">
                            <!-- <b-button
                                class="mr-2"
                                size="sm"
                                variant="outline-danger"
                                @click="deleteTemplate(data.item)"
                            >
                                Delete
                            </b-button> -->
                            <b-button size="sm" variant="outline-secondary" @click="viewTemplate(data.item)">
                                View
                            </b-button>
                            <!-- Edit  -->
                            <b-button class="ml-2" size="sm" variant="outline-primary" @click="handleEdit(data.item)">
                                Edit
                            </b-button>
                        </template>
                    </b-table>

                    <div class="pt-2 d-flex align-items-center">
                        <b-form-group
                            label="Per page"
                            label-for="per-page-select"
                            label-cols-sm="6"
                            label-cols-md="4"
                            label-cols-lg="9"
                            label-size="sm"
                            label-class="fw-medium"
                            class="mb-0"
                        >
                            <b-form-select
                                id="per-page-select"
                                v-model="perPage"
                                :options="pageOptions"
                                size="sm"
                            ></b-form-select>
                        </b-form-group>
                        <div class="ml-auto">
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="records"
                                :per-page="perPage"
                                align="fill"
                                class="my-0"
                            ></b-pagination>
                        </div>
                    </div>
                </b-col>
            </b-row>

            <!-- Add Template Modal -->
            <b-modal id="addTemplateModal" centered :title="isEditing ? 'Edit Template' : 'Add Template'" hide-footer>
                <b-form @submit.stop.prevent="addTemplate">
                    <b-form-group label="Name" label-for="template-name">
                        <b-form-input id="template-name" v-model="name" required placeholder="Name Here"></b-form-input>
                    </b-form-group>
                    <b-form-group label="File" label-for="template-file">
                        <b-form-file
                            id="template-file"
                            v-model="file"
                            placeholder="Choose a file..."
                            drop-placeholder="Drop file here..."
                            accept=".pdf, .doc, .docx, .xls, .xlsx"
                            required
                        ></b-form-file>
                    </b-form-group>

                    <b-form-group label="Product Type" label-for="product-type">
                        <b-form-select
                            id="product-type"
                            v-model="productTypeId"
                            :options="productTypes"
                            value-field="id"
                            text-field="description"
                            required
                        ></b-form-select>
                    </b-form-group>
                    <b-form-group label="Repayment Method" label-for="repayment-method">
                        <b-form-select v-model="repaymentMethodId" size="sm" id="repayment" name="repayment" required>
                            <b-form-select-option value="1">CASH</b-form-select-option>
                            <b-form-select-option value="2">POB</b-form-select-option>
                            <b-form-select-option value="3">Direct debit</b-form-select-option>
                        </b-form-select>
                    </b-form-group>

                    <b-form-checkbox id="require-co-applicant" v-model="requireCoApplicant" class="mb-2">
                        Require Co-Applicant
                    </b-form-checkbox>

                    <b-form-checkbox id="require-guarantor" v-model="requireGuarantor">
                        Require Guarantor
                    </b-form-checkbox>

                    <b-form-checkbox id="isActive" v-model="isActive" class="mb-2"> Is active </b-form-checkbox>

                    <div class="d-flex justify-content-end mt-3">
                        <b-button type="submit" variant="primary" class="mt-3" :disabled="loadingSave"> Save </b-button>
                    </div>
                </b-form>
            </b-modal>
        </b-card>
    </b-overlay>
</template>

<script>
import { fileToBase64 } from '../../utils/file';

export default {
    name: 'CustomerLoanHistory',
    data() {
        return {
            perPage: 10,
            currentPage: 1,
            pageOptions: [5, 10, 15, 20],
            filter: null,
            loading: true,
            loadingSave: false,
            templates: [],
            records: 0,
            productTypes: [],

            file: null,
            name: null,
            productTypeId: null,
            repaymentMethodId: null,
            requireCoApplicant: false,
            requireGuarantor: false,
            isEditing: false,
            templateId: null,
            isActive: false,
        };
    },
    computed: {},
    methods: {
        async handleEdit(template) {
            this.isEditing = true;
            this.templateId = template.id;
            this.name = template.name;
            this.productTypeId = template.productTypeId;
            this.repaymentMethodId = template.repaymentMethodId;
            this.requireCoApplicant = template.requireCoApplicant;
            this.requireGuarantor = template.requireGuarantor;
            this.isActive = template.isActive;
            this.$bvModal.show('addTemplateModal');
        },
        clearData() {
            this.file = null;
            this.name = null;
            this.productTypeId = null;
            this.repaymentMethodId = null;
            this.requireCoApplicant = false;
            this.requireGuarantor = false;
            this.isActive = false;
            this.isEditing = false;
        },

        async getTemplates() {
            this.loading = true;
            const response = await this.$store.dispatch('loan/contracts/get_allTemplates', {
                page: this.currentPage,
                records: this.perPage,
                sortDirection: 'asc',
                sortProperty: 'id',
            });
            this.templates = response?.dataResult ?? [];
            this.records = response?.dataRecords ?? 0;
            this.loading = false;
        },
        async getProductTypes() {
            const response = await this.$store.dispatch('loan/types/get_allTypes', { skip: 1, take: 100 });
            this.productTypes = response?.dataResult ?? [];
        },
        async addTemplate() {
            this.loadingSave = true;
            const fileName = this.file.name;
            const base64 = await fileToBase64(this.file);

            const payload = {
                name: this.name,
                fileName,
                fileBase64: base64,
                productTypeId: this.productTypeId,
                repaymentMethodId: this.repaymentMethodId,
                requireCoApplicant: this.requireCoApplicant,
                requireGuarantor: this.requireGuarantor,
                isActive: this.isActive,
            };
            if (this.isEditing) {
                await this.$store.dispatch('loan/contracts/update_template', {
                    id: this.templateId,
                    data: payload,
                });
            } else {
                await this.$store.dispatch('loan/contracts/create_template', {
                    ...payload,
                });
            }
            await this.getTemplates();
            this.clearData();
            this.$bvModal.hide('addTemplateModal');
            this.loadingSave = false;
        },
        async viewTemplate(template) {
            const response = await this.$store.dispatch('auth/getSignedUrl', {
                id: template.id,
                resourceType: 'contract-template',
            });

            if (response?.dataResult) {
                const url = response.dataResult;
                window.open(url, '_blank');
            }
        },
    },
    created() {
        this.getTemplates();
        this.getProductTypes();
    },
};
</script>
